'use client';

import {
  useLocalization,
  useMediaQuery,
  useOnClickOutside
} from '@akinon/next/hooks';
import { Select } from './select';
import { Image } from '@akinon/next/components/image';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Locale } from '@akinon/next/types';
import { countries } from 'settings';
import { setCookie } from '@akinon/next/utils';
import { LocaleUrlStrategy } from '@akinon/next/localization';
import { Icon } from './icon';
import getSymbolFromCurrency from 'currency-symbol-map';
import { twMerge } from 'tailwind-merge';
import { chunkArray } from '@theme/utils/chunk-array';
import { Button } from './button';
import { useClearBasketMutation } from '@akinon/next/data/client/basket';

interface LanguageSelectProps {
  className?: string;
}

type ExtendedLocaleType = Locale & {
  longLabel?: string;
};

export const DesktopLanguageSelect = (props: LanguageSelectProps) => {
  const {
    t,
    locale,
    locales,
    setLocale,
    defaultLocaleValue,
    localeUrlStrategy,
    currency
  }: {
    t: (key: string) => string;
    locale: string;
    locales: ExtendedLocaleType[];
    setLocale: (locale: string) => void;
    defaultLocaleValue: string;
    localeUrlStrategy: string;
    currency: string;
  } = useLocalization();

  const [currentLanguage, currentCountry] = locale.split('-');
  const [clearBasket, { isLoading, isError }] = useClearBasketMutation();
  const [showPopover, setShowPopover] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const popoverRef = useRef(null);
  const popoverButtonRef = useRef(null);

  const currentLocale: ExtendedLocaleType = locales.find(
    (lang) => lang.value === locale
  );

  const currentCurrency = countries.find(
    (lang) => lang.value === currentCountry
  );

  const uniqueLanguageOptions = locales
    .filter(
      (l, i, arr) => arr.findIndex((t) => t.apiValue === l.apiValue) === i
    )
    .map((l) => ({
      value: l.apiValue,
      longlabel: l.longLabel,
      label: l.label
    }));

  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
  const [selectedCountry, setSelectedCountry] = useState(currentCountry);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newLocale = `${selectedLanguage}-${selectedCountry}`;
    const localePath =
      newLocale === defaultLocaleValue &&
      localeUrlStrategy !== LocaleUrlStrategy.ShowAllLocales
        ? '/'
        : `/${newLocale}`;

    const selectedCountryData = countries.find(
      (country) => country.value === selectedCountry
    );

    if (selectedCountryData) {
      setCookie('pz-set-currency', selectedCountryData.currency);
    }

    setLocale(`${selectedLanguage}-${selectedCountry}`);
    window.location.href = localePath;

    try {
      await clearBasket().unwrap();
    } catch (error) {
      console.error('Failed to clear the basket:', error);
    }
  };

  const handleChange = (e) => {
    const splittedValue = e.currentTarget.value.split('-');
    setSelectedLanguage(splittedValue[0]);
  };

  const grouppedCountriesByRegion = countries.reduce((acc, country) => {
    if (!acc[country.region]) {
      acc[country.region] = [];
    }

    acc[country.region].push(country);

    return acc;
  }, {});

  const [activeRegion, setActiveRegion] = useState(
    Object.keys(grouppedCountriesByRegion)[0]
  );

  useOnClickOutside(popoverRef, () => setShowPopover(false), 'click');
  useEffect(() => {
    const currentRegion = countries.find(
      (country) => country.value === locale.split('-')[1]
    )?.region;

    setActiveRegion(currentRegion);
  }, [currentCountry]);

  const [languageOptionsIsOpen, openLanguageOptions] = useState(false);

  const countryContinentOrderMapping = {
    'common.regions.africa': 5,
    'common.regions.asia': 0,
    'common.regions.europe': 1,
    'common.regions.america': 2,
    'common.regions.pacifica': 3,
    'common.regions.middleEast': 4
  };

  return (
    <div className="relative flex cursor-pointer select-none lg:static">
      <div
        className="flex items-center gap-2"
        ref={popoverButtonRef}
        onClick={() => {
          if (isDesktop) {
            setShowPopover((prev) => !prev);
          }
        }}
      >
        <Image
          src={`/flags/${currentCountry}.svg`}
          alt="Flag"
          width={24}
          height={16}
          className="block"
          unoptimized
        />

        <span className="hidden lg:block lg:font-medium lg:text-gray-500">
          {currentLocale?.label} /{' '}
          {getSymbolFromCurrency(currentCurrency.currency)}
        </span>
      </div>
      <div
        className={clsx('hidden lg:block', {
          'invisible opacity-0': !showPopover
        })}
      >
        <div
          ref={popoverRef}
          className="absolute right-0 top-[57px] hidden  w-full max-w-[1120px] bg-white p-6 pb-2 lg:block"
        >
          <div className="mb-6 flex items-center justify-between text-primary">
            <p className="text-2xl">{t('common.select_location')}</p>
            <div>
              <div
                onClick={() => openLanguageOptions((prev) => !prev)}
                className={clsx('flex items-center gap-2 text-primary', {
                  'text-secondary-600': languageOptionsIsOpen
                })}
              >
                <Icon
                  name="globe-2"
                  size={14}
                  className="flex size-6 items-center justify-center"
                />
                <p className="text-sm uppercase leading-5">TR / EN</p>
                {languageOptionsIsOpen && (
                  <Icon
                    name="chevron-down"
                    size={14}
                    className="flex size-6 items-center justify-center"
                  />
                )}
              </div>
              <Select
                open={languageOptionsIsOpen}
                setExternalOpenState={openLanguageOptions}
                onChange={(event) => {
                  event.preventDefault();
                  setShowPopover(true);
                  handleChange(event);
                }}
                options={uniqueLanguageOptions.map((lang) => ({
                  value: lang.value,
                  label: lang.longlabel
                }))}
                value={
                  uniqueLanguageOptions?.find((option) =>
                    option?.value?.startsWith(currentLanguage)
                  )?.value ?? locale
                }
                useRadioInOptions
                optionClassName="text-primary text-sm [&>label]:text-sm [&>label>span]:ml-3 py-[14px] pl-4 pr-4"
                data-testid="language"
                radioClassName="!size-4 min-w-4 checked:border-[5px]"
                selectWrapperClassName="w-[116px] top-2"
                className="hidden rounded-none border-lightBlue-600 px-4 [&_div]:text-base  [&_div]:text-primary [&_i]:text-primary"
              />
            </div>
          </div>

          <div className="mb-6 flex gap-2">
            {Object.keys(grouppedCountriesByRegion).map((region) => {
              return (
                <button
                  style={{ order: countryContinentOrderMapping[region] }}
                  key={region}
                  onClick={() => setActiveRegion(region)}
                  className={clsx(
                    'order-last pb-2 text-lg font-medium text-gray-700',
                    {
                      'border-b-2 border-primary text-primary':
                        region === activeRegion
                    }
                  )}
                >
                  <span className="block px-2 leading-7">{t(region)}</span>
                </button>
              );
            })}
          </div>

          <div
            className={twMerge(
              'grid grid-cols-4 gap-x-6 text-left',
              chunkArray(grouppedCountriesByRegion[activeRegion], 10)?.length <
                3 && 'grid-cols-2'
            )}
          >
            {chunkArray(grouppedCountriesByRegion[activeRegion], 10).map(
              (chunkItems, index) => {
                return (
                  <div key={index} className="country-chunk">
                    {chunkItems.map((country) => {
                      const currency = country.currency;
                      const countryName = t(country.localizationKey);

                      return (
                        <button
                          key={country.value}
                          onClick={() => {
                            setSelectedCountry(country.value);
                          }}
                          className={clsx(
                            'mb-4 flex h-5 items-center py-0.5 text-left text-sm text-primary hover:font-medium hover:text-secondary',
                            {
                              'font-medium': country.value === selectedCountry
                            }
                          )}
                        >
                          <Image
                            src={`/flags/${country?.value}.svg`}
                            alt={country.value}
                            width={24}
                            className="mr-2 flex h-4 w-6 items-center shadow-[0px_1px_3px_0px_#1018281A]"
                            height={16}
                          />
                          {countryName} /{'  '}{' '}
                          <span className="mx-1 uppercase">{currency}</span>{' '}
                          {getSymbolFromCurrency(currency)}
                        </button>
                      );
                    })}
                  </div>
                );
              }
            )}
          </div>
          <div className="flex w-full justify-center">
            <Button className="my-3" onClick={handleSubmit}>
              {t('common.submit_location')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
