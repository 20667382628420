'use client';

import { useLocalization } from '@akinon/next/hooks';
import { Image } from '@akinon/next/components/image';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Locale } from '@akinon/next/types';
import { countries } from 'settings';
import { setCookie } from '@akinon/next/utils';
import { LocaleUrlStrategy } from '@akinon/next/localization';
import { Icon } from './icon';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Button } from './button';
import { Radio } from './radio';
import { useAppSelector } from '@akinon/next/redux/hooks';
import { useClearBasketMutation } from '@akinon/next/data/client/basket';

type ExtendedLocaleType = Locale & {
  longLabel?: string;
};

export const MobileLanguageSelect = () => {
  const { locale, locales, currency } = useLocalization();

  const [_, currentCountry] = locale.split('-');

  const currentLocale: ExtendedLocaleType = locales.find(
    (lang) => lang.value === locale
  );

  const currentCurrency = countries.find(
    (lang) => lang.value === currentCountry
  );

  const [isCountrySelectionModalVisible, setCountrySelectionModalVisibility] =
    useState(false);

  const [isLanguageSelectionModalVisible, setLanguageSelectionModalVisibility] =
    useState(false);

  const isMobileMenuOpen = useAppSelector(
    (state) => state.header.isMobileMenuOpen
  );

  useEffect(() => {
    if (!isMobileMenuOpen) {
      setCountrySelectionModalVisibility(false);
      setLanguageSelectionModalVisibility(false);
    }
  }, [isMobileMenuOpen]);

  return (
    <>
      <div
        className="flex items-center gap-2"
        onClick={() => {
          setCountrySelectionModalVisibility(true);
        }}
      >
        <Image
          src={`/flags/${currentCountry}.svg`}
          alt="Flag"
          width={24}
          height={16}
          className="block"
          unoptimized
        />

        <span className="text-sm text-gray-850">
          {currentLocale?.label} /{' '}
          {getSymbolFromCurrency(currentCurrency?.currency)}{' '}
        </span>

        <Icon name="arrow-right" size={24} />
      </div>
      <CountrySelectionModal
        isOpen={isCountrySelectionModalVisible}
        openLanguageSelectionModal={() =>
          setLanguageSelectionModalVisibility(true)
        }
        closeCountrySelectionModal={() =>
          setCountrySelectionModalVisibility(false)
        }
      />
      <LanguageSelectionModal
        isOpen={isLanguageSelectionModalVisible}
        closeLanguageSelectionModal={() =>
          setLanguageSelectionModalVisibility(false)
        }
      />
    </>
  );
};

const CountrySelectionModal = ({
  isOpen,
  closeCountrySelectionModal,
  openLanguageSelectionModal
}) => {
  const {
    t,
    locale,
    locales,
    defaultLocaleValue,
    localeUrlStrategy,
    currency
  } = useLocalization();
  const [clearBasket, { isLoading, isError }] = useClearBasketMutation();

  const [currenctlanguage, currentCountry] = locale.split('-');

  const currentLocale: ExtendedLocaleType = locales.find(
    (lang) => lang.value === locale
  );

  const currentCurrency = countries.find(
    (lang) => lang.value === currentCountry
  );

  return (
    <div
      className={clsx(
        'invisible absolute -left-4 right-0 top-0 z-10 h-[calc(100dvh-108px)] w-screen translate-x-full transform overflow-auto bg-white  px-4 pb-4	 opacity-0 transition duration-500',
        {
          '!visible !translate-x-0 !opacity-100': isOpen
        }
      )}
    >
      <header
        className={clsx(
          'relative mb-2 flex items-center  justify-between gap-2 pb-4 pt-2'
        )}
      >
        <Button
          appearance="ghost"
          onClick={() => closeCountrySelectionModal()}
          className="flex !h-8 items-center gap-2 !p-0"
        >
          <Icon name="arrow-left" size={32} />
          <Image
            src={`/flags/${currentCountry}.svg`}
            alt="Flag"
            width={24}
            height={16}
            className="block"
            unoptimized
          />
          <span className="text-sm text-primary-500">
            {currentLocale?.label} /{' '}
            {getSymbolFromCurrency(currentCurrency.currency)}
          </span>
        </Button>
        <button
          onClick={() => openLanguageSelectionModal()}
          className={clsx('flex items-center gap-2 text-primary')}
        >
          <Icon
            name="globe-2"
            size={14}
            className="flex size-6 items-center justify-center"
          />
          <p className="text-sm uppercase leading-5">TR / EN</p>

          <Icon
            name="arrow-right"
            size={24}
            className="flex size-6 items-center justify-center"
          />
        </button>
      </header>
      <div className="flex flex-col">
        {countries.map((country) => {
          return (
            <button
              key={country.value}
              onClick={async () => {
                const selectedCountryData = countries.find(
                  (c) => c.value === country.value
                );
                if (selectedCountryData) {
                  setCookie('pz-set-currency', selectedCountryData.currency);
                }

                const locale = `${currenctlanguage}-${country.value}`;

                const localePath =
                  locale === defaultLocaleValue &&
                  localeUrlStrategy !== LocaleUrlStrategy.ShowAllLocales
                    ? '/'
                    : `/${locale}`;

                location.href = localePath;

                try {
                  await clearBasket().unwrap();
                } catch (error) {
                  console.error('Failed to clear the basket:', error);
                }
              }}
              className={clsx(
                'mb-6 flex h-5 items-center text-left text-sm text-primary hover:text-secondary',
                {
                  'font-medium': country.value === currentCountry
                }
              )}
            >
              <Image
                src={`/flags/${country?.value}.svg`}
                alt={country.value}
                width={24}
                className="mr-2 flex h-4 w-6 items-center shadow-[0px_1px_3px_0px_#1018281A]"
                height={16}
              />
              {t(country.localizationKey)} /{'  '}{' '}
              <span className="mx-1 uppercase">{country?.currency}</span>{' '}
              {getSymbolFromCurrency(country?.currency)}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const LanguageSelectionModal = ({ isOpen, closeLanguageSelectionModal }) => {
  const {
    locale,
    locales,
    setLocale
  }: {
    locale: string;
    locales: ExtendedLocaleType[];
    setLocale: (locale: string) => void;
  } = useLocalization();

  const [currenctlanguage, currentCountry] = locale.split('-');

  const languageOptions = locales
    .filter(
      (l, i, arr) => arr.findIndex((t) => t.apiValue === l.apiValue) === i
    )
    .map((l) => ({
      value: l.apiValue,
      longlabel: l.longLabel,
      label: l.label
    }));

  return (
    <div
      className={clsx(
        'invisible absolute -left-4 right-0 top-0 z-20 h-[calc(100dvh-108px)] w-screen translate-x-full transform overflow-auto bg-white  px-4 pb-4	 opacity-0 transition duration-500',
        {
          '!visible !translate-x-0 !opacity-100': isOpen
        }
      )}
    >
      <header
        className={clsx(
          'relative mb-2 flex items-center  justify-between gap-2 pb-4 pt-2'
        )}
      >
        <Button
          appearance="ghost"
          onClick={() => closeLanguageSelectionModal()}
          className="flex !h-8 items-center gap-2 !p-0"
        >
          <Icon name="arrow-left" size={32} />
          <span className="text-sm uppercase text-primary-500">TR / EN</span>
        </Button>
      </header>
      <div className="flex flex-col">
        {languageOptions.map((language, index) => {
          const label = language.longlabel || language.label;
          const [languageValue, _] = language.value.split('-');
          return (
            <div key={index} className="mb-4 py-0.5 text-base font-medium">
              <Radio
                onChange={(e) => {
                  const selectedLanguage = e?.currentTarget?.value;
                  setLocale(`${selectedLanguage}-${currentCountry}`);
                }}
                name="mobile-language-radio"
                value={languageValue}
                spanClassName="ml-3"
                checked={currenctlanguage === languageValue}
              >
                {label}
              </Radio>
            </div>
          );
        })}
      </div>
    </div>
  );
};
