import { IconProps } from '@theme/components/types';
import { twMerge } from 'tailwind-merge';

export const Icon = (props: IconProps) => {
  const { name, size, className, onClick, ...rest } = props;

  return (
    <i
      className={twMerge(`flex pz-icon-${name}`, className)}
      {...rest}
      style={
        size && {
          fontSize: `${size}px`,          
          ...(rest.style?.color && { color: rest.style.color })
        }
      }
      onClick={onClick}
    />
  );
};
