'use client';

import ReactPortal from './react-portal';

import { Icon } from './icon';
import { twMerge } from 'tailwind-merge';
import { useEffect, useRef } from 'react';
export interface ModalProps {
  portalId: string;
  children?: React.ReactNode;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  title?: React.ReactNode;
  titleClassName?: string;
  showCloseButton?: React.ReactNode;
  className?: string;
  classNameHeader?: string;
  quickShop?: boolean;
  toRight?: boolean;
  iconClassName?: string;
  closeIconSize?: number;
  draggableMove?: boolean;
  classBackground?: string;
  modalIcon?: string;
  isClosable?: boolean;
}

export const Modal = (props: ModalProps) => {
  const {
    children,
    portalId,
    open,
    setOpen,
    title = '',
    titleClassName,
    showCloseButton = true,
    className,
    classNameHeader,
    quickShop,
    toRight,
    iconClassName,
    closeIconSize,
    draggableMove,
    classBackground,
    modalIcon = 'close',
    isClosable = true
  } = props;

  const drawElRef = useRef(null);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  useEffect(() => {
    if (!isClosable) return;
    const handleEscape = (event: KeyboardEvent) => {
      if (setOpen && event.key === 'Escape') {
        setOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isClosable, setOpen]);

  if (!open) return null;

  const modalClass = toRight
    ? 'fixed right-0 top-1/2 transform -translate-y-1/2 translate-x-0 z-50 bg-white transition-all duration-300'
    : 'fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white';

  const modalCloseIconSize = closeIconSize ? closeIconSize : 16;

  const handleDrag = (e) => {
    const top = e.touches[0].clientY;
    const elTop =
      drawElRef.current && drawElRef.current.getBoundingClientRect();
    if (elTop.top < top || window.innerHeight - elTop.height < top) {
      drawElRef.current.style.top = `${top}px`;
    }
  };

  const handleDragEnd = (e) => {
    const top = e.changedTouches[0].clientY;
    if (top > 50) {
      setOpen(false);
    }
    drawElRef.current.style.top = 'auto';
  };

  return (
    <ReactPortal wrapperId={portalId}>
      <div
        onClick={() => isClosable && setOpen(false)}
        className={twMerge(
          'fixed left-0 top-0 z-50 h-screen w-screen bg-primary bg-opacity-60',
          classBackground
        )}
      />
      <section ref={drawElRef} className={twMerge(modalClass, className)}>
        {draggableMove && (
          <div
            className="z-50 mb-2 mt-4 flex h-4 w-full items-center justify-center md:hidden"
            onTouchMove={handleDrag}
            onTouchEnd={handleDragEnd}
          >
            <div className="h-1 w-14 rounded-lg bg-[#e5e7eb]"></div>
          </div>
        )}
        {quickShop && (
          <div className="mt-6 flex h-1 w-full justify-center">
            <span className="h-full w-[58px] bg-[#e5e7eb]"></span>
          </div>
        )}
        {(showCloseButton || title) && (
          <div
            className={twMerge(
              'flex border-b border-gray-400 px-6 py-4',
              classNameHeader
            )}
          >
            {title && (
              <h3 className={twMerge('text-lg font-light', titleClassName)}>
                {title}
              </h3>
            )}
            {showCloseButton && isClosable && (
              <button
                type="button"
                onClick={() => setOpen(false)}
                className={twMerge('ml-auto', iconClassName)}
              >
                <Icon name={modalIcon} size={modalCloseIconSize} />
              </button>
            )}
          </div>
        )}
        {children}
      </section>
    </ReactPortal>
  );
};
