import clsx from 'clsx';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Props as TabProps } from './tab';
import { Tab } from './tab';

type Props = {
  children: ReactElement<TabProps>[];
  preSelectedTabIndex?: number;
  tabBarPosition?: string;
  className?: string;
  tabsWrapperClassName?: string;
  tabsWrapperRef?: React.Ref<HTMLDivElement>;
  onTabChange?: () => void;
};

export const Tabs = (props: Props) => {
  const {
    children,
    preSelectedTabIndex,
    tabBarPosition,
    className,
    tabsWrapperClassName,
    ...rest
  } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    preSelectedTabIndex >= 0 ? preSelectedTabIndex : 0
  );

  const tabsElement = useRef(null);

  useEffect(() => {
    if (preSelectedTabIndex !== undefined && preSelectedTabIndex >= 0) {
      setSelectedTabIndex(preSelectedTabIndex);
    }
  }, [preSelectedTabIndex]);

  useEffect(() => {
    if (tabsElement.current) {
      const scrollLeft =
        tabsElement?.current?.children[selectedTabIndex]?.offsetLeft;
      if (scrollLeft) {
        tabsElement.current.scrollLeft = scrollLeft - 62;
      }
    }
  }, [selectedTabIndex]);

  return (
    <div
      className={twMerge(
        clsx({
          'flex flex-col-reverse': tabBarPosition === 'bottom',
          'flex flex-row gap-4':
            tabBarPosition === 'left' || tabBarPosition === 'right',
          'flex-row-reverse': tabBarPosition === 'right'
        }),
        className
      )}
      {...rest}
    >
      <div className="tabs-wrapper" ref={rest.tabsWrapperRef}>
        <ul
          ref={tabsElement}
          className={clsx(
            'mb-4 flex w-full snap-x snap-mandatory flex-nowrap gap-4 overflow-x-auto',
            {
              'max-w-[10rem] flex-col':
                tabBarPosition === 'left' || tabBarPosition === 'right'
            },
            {
              '!scrollbar-hide snap-x snap-mandatory overflow-x-auto':
                selectedTabIndex
            },
            tabsWrapperClassName
          )}
        >
          {children &&
            children?.map((item, index) => (
              <Tab
                key={item?.props?.title}
                title={item?.props?.title}
                index={index}
                icon={item?.props?.icon}
                iconPosition={item?.props?.iconPosition}
                isActive={index === selectedTabIndex}
                onClick={item?.props?.onClick}
                setSelectedTab={setSelectedTabIndex}
                className={twMerge(
                  'h-[38px] w-auto flex-shrink-0 snap-center',
                  item?.props?.tabClassName
                )}
                activeTabClassName={item?.props?.activeTabClassName}
              />
            ))}
        </ul>
      </div>
      {children && <div className="w-full">{children[selectedTabIndex]}</div>}
    </div>
  );
};
