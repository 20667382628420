import clsx from 'clsx';
import { forwardRef, FocusEvent, useState, Ref } from 'react';
import { Controller } from 'react-hook-form';
import {
  NumberFormatBase,
  NumberFormatBaseProps,
  PatternFormat,
  PatternFormatProps
} from 'react-number-format';
import { InputProps } from '@theme/components/types';
import { twMerge } from 'tailwind-merge';

const PatternFormatWithRef = forwardRef(
  (
    props: PatternFormatProps & InputProps & NumberFormatBaseProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return props.useFormatBase ? (
      <NumberFormatBase {...props} getInputRef={ref} />
    ) : (
      <PatternFormat {...props} getInputRef={ref} />
    );
  }
);
PatternFormatWithRef.displayName = 'PatternFormatWithRef';

export const Input = forwardRef<
  HTMLInputElement,
  InputProps &
    Pick<
      PatternFormatProps,
      'mask' | 'allowEmptyFormatting' | 'onValueChange'
    > & {
      format?: string | ((value: string) => string);
    } & Pick<
      NumberFormatBaseProps,
      'getCaretBoundary' | 'removeFormatting' | 'isValidInputCharacter'
    >
>((props, ref) => {
  const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const {
    id,
    label,
    extraLabel,
    extraLabelClassName,
    labelStyle,
    labelClassName,
    wrapperClassName,
    error,
    mask,
    format,
    errorClassName,
    fromToSecondInput = false,
    icon,
    iconClassName,
    inputClassName,
    required = false,
    ...rest
  } = props;
  const hasFloatingLabel = label && labelStyle === 'floating';
  const inputClass = twMerge(
    clsx(
      'autofill-custom-style border border-lightBlue-600 px-[22px] text-sm placeholder:text-gray-850 lg:text-xs',
      'focus-visible:outline-none',
      inputClassName,
      hasFloatingLabel ? '-floating-label border-0 border-b pt-5' : 'h-10',
      error
        ? 'border-error focus:border-error'
        : 'border-gray hover:border-primary focus:border-primary'
    ),
    props.className
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputProps: any = {
    id,
    ref,
    className: inputClass,
    onFocus: (e) => {
      setFocused(true);
      rest.onFocus && rest.onFocus?.(e);
    },
    onBlur: (event: FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      setHasValue(!!event.target.value);
      rest.onBlur && rest.onBlur(event);
    }
  };

  const Label = () => {
    if (!label) return null;

    return (
      <label
        htmlFor={id}
        className={twMerge(
          clsx(
            'text-sm leading-normal',

            {
              'autofill-custom-style -floating-label absolute left-2 top-1/2 origin-left -translate-y-1/2 text-gray transition-all duration-500':
                hasFloatingLabel
            },
            {
              'mb-1.5 font-medium leading-[1.43] text-gray': !hasFloatingLabel
            },
            {
              '-translate-y-full scale-75 transition-all duration-500':
                hasFloatingLabel && (focused || hasValue)
            },
            labelClassName
          )
        )}
      >
        {label}
        {required && <span className="text-gray"> *</span>}
        {extraLabel && (
          <span className={extraLabelClassName}>{extraLabel}</span>
        )}
      </label>
    );
  };

  return (
    <div className={twMerge(clsx('flex flex-col'), wrapperClassName)}>
      <div className="relative flex flex-col">
        {icon && (
          <div
            className={clsx(
              'absolute bottom-[-7px] left-4 -translate-y-1/2 transform',
              iconClassName
            )}
          >
            <i className={icon}></i>
          </div>
        )}
        {props.format ? (
          <>
            {labelStyle !== 'floating' && <Label />}
            <Controller
              name={props.name ?? ''}
              control={props.control}
              render={({ field }) => {
                const modifiedValue =
                  field?.value && field?.value.length == 11
                    ? field?.value.substring(2, 11)
                    : field?.value;
                const updatedField = {
                  ...field,
                  value: modifiedValue
                };
                return (
                  <PatternFormatWithRef
                    format={format}
                    mask={mask ?? '_'}
                    {...rest}
                    {...(fromToSecondInput ? updatedField : field)}
                    {...inputProps}
                    type={props.type as 'text' | 'password' | 'tel'}
                  />
                );
              }}
            />
            {labelStyle === 'floating' && <Label />}
          </>
        ) : (
          <>
            {labelStyle !== 'floating' && <Label />}
            <input {...rest} {...inputProps} />
            {labelStyle === 'floating' && <Label />}
          </>
        )}
      </div>
      {error && (
        <span className={twMerge('mt-1 text-sm text-error', errorClassName)}>
          {error.message}
        </span>
      )}
    </div>
  );
});

Input.displayName = 'Input';
